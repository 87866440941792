<template>
  <v-container fluid class="quotation-detail-page">
    <v-row>
      <v-col cols="5" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative;"
        >
          <table width="100%">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Sale Info</th>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Sale ID</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.barcode && detail.barcode != 'null'"
                >
                  <span class="text-capitalize">
                    <!-- <v-chip outlined text-color="" label small color="blue">
                    {{ detail.barcode }}
                  </v-chip> -->
                    <Barcode
                      route="sell.detail"
                      :barcode="detail.barcode"
                      :id="detail.id"
                    >
                    </Barcode>
                  </span>
                </template>
                <template v-else> no sale ID </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Title</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.title && detail.title != 'null'"
                >
                  <span class="text-capitalize">{{ detail.title }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no title</span></template
                >
              </td>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">
                Sale Invoice No.
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.sales_invoice_no &&
                    detail.sales_invoice_no != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.sales_invoice_no
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no sale invoice no.</span></template
                >
              </td>
            </tr>

            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Sale Agreement</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.sales_agreement &&
                    detail.sales_agreement != 'null'
                  "
                >
                  <span class="text-capitalize">{{
                    detail.sales_agreement
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no sale agreement</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Date</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.date && detail.date != 'null'">
                  <span class="text-capitalize">{{
                    formatDate(detail.date)
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no date</span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Sale By</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.sales_person &&
                    detail.sales_person != 'null'
                  "
                >
                  <span class="text-capitalize">{{ detail.sales_person }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no sale by</span></template
                >
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="7" class="pb-0 pl-4">
        <v-row>
          <v-col md="12">
            <span
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
              >SOLD TO</span
            >
          </v-col>
          <v-col md="12">
            <span
              v-on:click="routeToCustomer()"
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"  
              >{{ detail?.customer_relation?.display_name }}</span
            >
          </v-col>
          <v-col md="6">
            <label class="my-0 p-0" style="font-size: 16px"
              >Billing Location</label
            >
            <template v-if="detail?.billing_relation?.id">
              <table width="100%">
                <tr v-if="detail?.billing_relation?.property_name">
                  <td class="p-0">
                    {{ detail?.billing_relation?.property_name }}
                  </td>
                </tr>
                <tr v-if="detail?.billing_relation?.street_1">
                  <td class="p-0">{{ detail?.billing_relation?.street_1 }}</td>
                </tr>
                <tr v-if="detail?.billing_relation?.street_2">
                  <td class="p-0">{{ detail?.billing_relation?.street_2 }}</td>
                </tr>
                <tr v-if="detail?.billing_relation?.unit_no">
                  <td class="p-0">{{ detail?.billing_relation?.unit_no }}</td>
                </tr>
                <tr v-if="detail?.billing_relation?.zip_code">
                  <td class="p-0">{{ detail?.billing_relation?.zip_code }}</td>
                </tr>
              </table>
            </template>
            <label class="mt-3 p-0" style="font-size: 16px"
              >Contact Details</label
            >
            <template v-if="detail?.billing_contact_person_relation?.id">
              <table width="100%">
                <tr
                  v-if="detail?.billing_contact_person_relation?.display_name"
                >
                  <td class="p-0">
                    {{ detail?.billing_contact_person_relation?.display_name }}
                  </td>
                </tr>
                <tr
                  v-if="detail?.billing_contact_person_relation?.primary_phone"
                >
                  <td class="p-0">
                    {{ detail?.billing_contact_person_relation?.primary_phone }}
                  </td>
                </tr>
                <tr
                  v-if="detail?.billing_contact_person_relation?.primary_email"
                >
                  <td class="p-0">
                    {{ detail?.billing_contact_person_relation?.primary_email }}
                  </td>
                </tr>
              </table>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0 p-0" style="font-size: 16px"
              >Site Location</label
            >
            <template v-if="detail?.property_relation?.id">
              <table width="100%">
                <tr v-if="detail?.property_relation?.property_name">
                  <td class="p-0">
                    {{ detail?.property_relation?.property_name }}
                  </td>
                </tr>
                <tr v-if="detail?.property_relation?.street_1">
                  <td class="p-0">{{ detail?.property_relation?.street_1 }}</td>
                </tr>
                <tr v-if="detail?.property_relation?.street_2">
                  <td class="p-0">{{ detail?.property_relation?.street_2 }}</td>
                </tr>
                <tr v-if="detail?.property_relation?.unit_no">
                  <td class="p-0">{{ detail?.property_relation?.unit_no }}</td>
                </tr>
                <tr v-if="detail?.property_relation?.zip_code">
                  <td class="p-0">{{ detail?.property_relation?.zip_code }}</td>
                </tr>
              </table>
            </template>
            <label class="mt-3 p-0" style="font-size: 16px"
              >Contact Details</label
            >
            <template v-if="detail?.property_contact_person_relation?.id">
              <table width="100%">
                <tr
                  v-if="detail?.property_contact_person_relation?.display_name"
                >
                  <td class="p-0">
                    {{ detail?.property_contact_person_relation?.display_name }}
                  </td>
                </tr>
                <tr
                  v-if="detail?.property_contact_person_relation?.primary_phone"
                >
                  <td class="p-0">
                    {{
                      detail?.property_contact_person_relation?.primary_phone
                    }}
                  </td>
                </tr>
                <tr
                  v-if="detail?.property_contact_person_relation?.primary_email"
                >
                  <td class="p-0">
                    {{
                      detail?.property_contact_person_relation?.primary_email
                    }}
                  </td>
                </tr>
              </table>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.quotation-detail-page {
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>

<script>
import moment from "moment-timezone";
//import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    // Status,
    Barcode,
  },
};
</script>
