var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pl-4",attrs:{"cols":"6"}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"89vh position: relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('table',{attrs:{"width":"100%"}},[_c('tr',[_c('th',{staticClass:"font-size-18 py-2",attrs:{"colspan":"2"}},[_vm._v("Extended Info")])]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Asset Type")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail && _vm.detail.asset_type && _vm.detail.asset_type != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.asset_type))])]:[_c('span',{staticClass:"text-muted"},[_vm._v(" no asset type")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Owner Ref.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail && _vm.detail.owner_ref && _vm.detail.owner_ref != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.owner_ref))])]:[_c('span',{staticClass:"text-muted"},[_vm._v(" no owner ref. ")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Year")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.year && _vm.detail.year != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.year))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no year")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Platform Height")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.plateform_height &&
                  _vm.detail.plateform_height != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.plateform_height))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no plateform height")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Engine Type")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail && _vm.detail.engine_type && _vm.detail.engine_type != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.engine_type))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no engine type")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Capacity")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.capacity && _vm.detail.capacity != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.capacity))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no capacity")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Code")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.code && _vm.detail.code != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.code))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no code")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Chassis Number")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.chassis_number &&
                  _vm.detail.chassis_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.chassis_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no chassis number")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Engine Number")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.engine_number &&
                  _vm.detail.engine_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.engine_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no engine number")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("LTA Plate No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.lta_plate_no &&
                  _vm.detail.lta_plate_no != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.lta_plate_no))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no lta plate no")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("NEA Plate No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.nea_plate_no &&
                  _vm.detail.nea_plate_no != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.nea_plate_no))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no nea plate no")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Original Fork C/W ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.originial_fork &&
                  _vm.detail.originial_fork != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.originial_fork))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no original fork c/w")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("NOA")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.noa && _vm.detail.noa != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.noa))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no NOA")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("LP/LM Cert")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.lp_cert && _vm.detail.lp_cert != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.lp_cert))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no lp/lm cert.")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" LP Cert Inspection Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.equip_start_date &&
                  _vm.detail.equip_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.equip_start_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no LP cert. inspection date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" LP Cert Expiry Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.equip_end_date &&
                  _vm.detail.equip_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.equip_end_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no LP cert expiry date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Machinery All Risk ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.machinery_all_risk &&
                  _vm.detail.machinery_all_risk != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.machinery_all_risk))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no machinery all risk")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Hour meter")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail && _vm.detail.hour_meter && _vm.detail.hour_meter != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.hour_meter))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no hour meter")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Breakdown log")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.breakdown_log &&
                  _vm.detail.breakdown_log != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.breakdown_log))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no breakdown log")])]],2)])])])],1),(_vm.detail.type != 'cross-rent')?_c('v-col',{staticClass:"pb-0 pl-4",attrs:{"cols":"6"}},[(_vm.allowPurchaseinfo)?_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"89vh position: relative","border-left":"1px solid lightgray !important"},attrs:{"options":{ suppressScrollX: true }}},[_c('table',{attrs:{"width":"100%"}},[_c('tr',[_c('th',{staticClass:"font-size-18 py-2",attrs:{"colspan":"2"}},[_vm._v("Purchase Info")])]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Cost")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail && _vm.detail.equip_cost && _vm.detail.equip_cost != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_c('v-chip',{attrs:{"outlined":"","text-color":"","label":"","small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.detail.equip_cost)+" "+_vm._s(_vm.detail?.equip_cost_currency && _vm.detail.equip_cost_currency != "null" ? _vm.detail.equip_cost_currency : null)+" ")])],1)]:[_vm._v(" no cost ")]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Purchase Invoice Number ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.purchase_invoice_number &&
                  _vm.detail.purchase_invoice_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.purchase_invoice_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no purchase invoice number")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Contract No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.contract_number &&
                  _vm.detail.contract_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.contract_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no contract no.")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Bought From")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.brought_from &&
                  _vm.detail.brought_from != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.brought_from))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no Bought From")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Finance Company")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.finance_company &&
                  _vm.detail.finance_company != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_c('Barcode',{attrs:{"route":"supplier.detail","barcode":_vm.detail.finance_barcode,"id":_vm.detail.finance_company}})],1)]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance company")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance Company Name ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.finance_company_name &&
                  _vm.detail.finance_company_name != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.detail.finance_company_name))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance company name")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance Start Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.finance_start_date &&
                  _vm.detail.finance_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.finance_start_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance start date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance End Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.finance_end_date &&
                  _vm.detail.finance_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.finance_end_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance end date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Company ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.insurance_company &&
                  _vm.detail.insurance_company != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.insurance_company))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance company")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Policy ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.insurance_policy &&
                  _vm.detail.insurance_policy != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.insurance_policy))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance policy")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Start Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.insurance_start_date &&
                  _vm.detail.insurance_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.insurance_start_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance start date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance End Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.insurance_end_date &&
                  _vm.detail.insurance_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.insurance_end_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance end date")])]],2)]),_c('tr',[_c('th',{staticClass:"font-size-18 py-2",attrs:{"colspan":"2"}},[_vm._v("Import Info")])]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Arrival Date")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.import_arrival_date &&
                  _vm.detail.import_arrival_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.import_arrival_date))])]:[_c('span',{staticClass:"text-muted"},[_vm._v(" no import arrival date")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Permit No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.import_permit_no &&
                  _vm.detail.import_permit_no != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.import_permit_no))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no permit no.")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("BOL No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.import_bol_no &&
                  _vm.detail.import_bol_no != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.import_bol_no))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no nol no.")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("DO No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(_vm.detail && _vm.detail.do_no && _vm.detail.do_no != 'null')?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.do_no))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no do no.")])]],2)])])]):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }