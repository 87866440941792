<template>
  <div>
    <DetailTemplate
      :customClass="'equipment-detail detail-page'"
      v-if="getPermission('equipment:view')"
    >
      <template v-slot:header-title>
        <div class="d-flex">
          <template v-if="pageLoading">
            <v-skeleton-loader
              class="custom-skeleton height-40px custom-skeleton-full-width"
              width="600"
              type="text"
            >
            </v-skeleton-loader>
          </template>
          <template v-else>
            <h1
              class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-3"
            >
              {{ detail.name }}
            </h1>
          </template>
          <v-chip color="cyan" label text-color="white" class="mr-2">
            {{ detail.barcode }}
          </v-chip>
          <v-chip
            label
            :color="detail.main_type == 'main_customer' ? '#4CAF50' : '#0D47A1'"
            class="text-white p-3 mr-2 mb-1"
          >
            <template v-if="detail.main_type == 'main_customer'">
              Customer
            </template>
            <template v-else> A&G Own Equipment</template>
          </v-chip>
          <v-chip
            label
            :color="detail.type == 'personal' ? 'cyan' : 'red'"
            outlined
            class="text-white p-3 mr-3"
            v-if="detail.type == 'personal'"
          >
            <template v-if="detail.type == 'personal'">
              Customer Own Equipment
            </template>
            <!-- <template v-else> A&G Purchased</template> -->
          </v-chip>

          <!-- <v-chip
            label
            color="#0d47a1"
            v-if="detail.main_type == 'main_company'"
            outlined
            class="p-3 mr-5"
          >
            Own Equipment
          </v-chip> -->

          <v-chip
            :color="getStatusColor(detail.status_value)"
            label
            :text-color="detail.status_value == 'In-Active' ? 'black' : 'white'"
            class="mr-2"
            v-if="detail && detail.status_value"
          >
            {{ detail.status_value }}
          </v-chip>

          <v-chip
            label
            color="#0d47a1"
            v-if="detail.type == 'cross-rent'"
            outlined
            class="p-3 mr-5"
          >
            CROSS RENT
          </v-chip>

          <v-chip
            label
            color="red"
            class="text-white p-3 mr-5 mb-1"
            v-if="detail.is_sold"
          >
            Sold
          </v-chip>
          <v-chip
            label
            color="green"
            class="text-white p-3 mr-2 mb-1"
            v-else-if="detail.is_rental"
          >
            Rental
          </v-chip>

          <v-chip
            label
            color="green"
            class="text-white p-3 mr-2 mb-1"
            v-if="detail.is_approved_sub_rental_exist"
          >
            Sub Rent
          </v-chip>

          <v-chip
            :color="getStatusColor('Faulty')"
            label
            text-color="white"
            class="mr-2"
            v-if="detail && detail.faulty"
          >
            Faulty
          </v-chip>
          <v-chip
            :color="getStatusColor('Retired')"
            label
            text-color="white"
            class="mr-2"
            v-if="detail && detail.retired"
          >
            Retired
          </v-chip>
        </div>
      </template>
      <template v-slot:header-action>
        <v-menu
          style="z-index: 99 !important"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in equipmentMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
                v-if="!more.hide"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <template v-if="getPermission('equipment:update')">
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :to="
              getDefaultRoute('equipment.update', {
                param: { id: equipment },
              })
            "
          >
            <v-icon small left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>

        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-tabs
            v-model="equipmentTab"
            background-color="transparent"
            color="cyan"
            class="custom-tab-transparent"
          >
            <template v-for="(tab, index) in tabs">
              <v-tab
                v-if="
                  getPermission(tab.permission + ':view') &&
                  tab.conditional &&
                  tab.key == 'cross-rent-info' &&
                  detail.type == 'cross-rent'
                "
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                </span>
                {{ tab.title }}
              </v-tab>

              <v-tab
                v-if="
                  getPermission(tab.permission + ':view') &&
                  tab.conditional &&
                  tab.key == 'sale-info' &&
                  detail.is_sold == 1
                "
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                </span>
                {{ tab.title }}
              </v-tab>

              <v-tab
                v-if="
                  getPermission(tab.permission + ':view') &&
                  tab.conditional &&
                  tab.key == 'rental-info' &&
                  detail.is_rental_exist == 1
                "
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                </span>
                {{ tab.title }}
              </v-tab>

              <v-tab
                v-if="
                  getPermission(tab.permission + ':view') &&
                  tab.conditional &&
                  tab.key == 'sub-rent-info' &&
                  detail.is_sub_rental_exist == 1
                "
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                </span>
                {{ tab.title }}
              </v-tab>

              <v-tab
                v-if="
                  getPermission(tab.permission + ':view') && !tab.conditional
                "
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#' + tab.key"
                :disabled="tab.disabled"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
          <v-tabs-items v-model="equipmentTab">
            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'equipment'"
            >
              <Overview :detail="detail"></Overview>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'extended-info'"
            >
              <ExtendedInformation :detail="detail" :allow-purchaseinfo="allowPurchaseinfo"></ExtendedInformation>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'cross-rent-info'"
            >
              <CrossRentInformation :detail="detail"></CrossRentInformation>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'sale-info'"
            >
              <SaleInformation :detail="detail?.sell_detail"></SaleInformation>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'rental-info'"
            >
              <RentalItemsEquipment
                :equipment-id="detail.id"
                :detail="detail"
                type="equipment"
              >
              </RentalItemsEquipment>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'sub-rent-info'"
            >
              <SubRentalItemsEquipment
                :equipment-id="detail?.id"
                :detail="detail"
                :rental-item="0"
                type="equipment"
                module="equipment"
              >
              </SubRentalItemsEquipment>
            </v-tab-item>

            <v-tab-item value="attachment">
              <AdditionalDocuments
                :type-id="detail.id"
                class="mx-4"
                type-text="Equipment"
                :type="37"
                :extensive="false"
              ></AdditionalDocuments>
            </v-tab-item>

            <v-tab-item v-if="getPermission('equipment:view')" :value="'image'">
              <Images
                :images="detail.product_images"
                :parent="detail.id"
                type="equipment"
                :isPageLoading="pageLoading"
              ></Images>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('warranty:view')"
              :value="'warranty'"
            >
              <Warranty :detail="detail"></Warranty>
            </v-tab-item>
            <v-tab-item v-if="getPermission('job:view')" :value="'job'">
              <Tickets :detail="detail" type="product"></Tickets>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('quotation:view')"
              :value="'quotation'"
            >
              <Quotations :detail="detail" type="product"></Quotations>
            </v-tab-item>
            <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
              <Invoices :detail="detail" type="product"></Invoices>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'history'"
            >
              <InternalHistoryDetail
                type="equipment"
                :type_id="detail.id"
              ></InternalHistoryDetail>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </template>
    </DetailTemplate>
    <statusConfirmation
      v-if="faultyDialog"
      type="Equipment"
      v-on:success="
        faultyDialog = false;
        getEquipmentDetail();
      "
      v-on:close="faultyDialog = false"
      :approve-dialog="faultyDialog"
      :status="statusValue"
      :uuid="equipment"
      approve-type="equipments"
      statusText="Mark as Faulty"
      :status-value="statusVal"
    >
    </statusConfirmation>

    <retiredConfirmation
      v-if="retireDialog"
      type="Equipment"
      v-on:success="
        retireDialog = false;
        getEquipmentDetail();
      "
      v-on:close="retireDialog = false"
      :approve-dialog="retireDialog"
      :status="statusValue"
      :uuid="equipment"
      statusText="Mark as Retired"
      approve-type="equipments"
      :status-value="statusVal"
    >
    </retiredConfirmation>
  </div>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/equipment/detail/Overview";
import ExtendedInformation from "@/view/pages/equipment/detail/ExtendedInformation";
import CrossRentInformation from "@/view/pages/equipment/detail/CrossRentInformation";
import SaleInformation from "@/view/pages/equipment/detail/SaleInformation";
import RentalItemsEquipment from "@/view/pages/partials/Detail/RentalItemsEquipment";

import Warranty from "@/view/pages/equipment/detail/Warranty";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import statusConfirmation from "@/view/components/statusConfirmation";
import retiredConfirmation from "@/view/components/statusConfirmation";
import SubRentalItemsEquipment from "@/view/pages/partials/Detail/SubRentalItemsEquipment";

export default {
  data() {
    return {
      allowPurchaseinfo:0,
      userInfo:null,
      detail: new Object(),
      equipmentTab: "equipment",
      // equipmentMoreAction: [
      //   {
      //     title: "Duplicate",
      //     action: "duplicate",
      //     icon: "mdi-content-duplicate",
      //     disabled: false,
      //   },
      //   {
      //     title: "Mark as Active",
      //     action: "mark_as_active",
      //     icon: "mdi-check-all",
      //     disabled: false,
      //   },
      //   {
      //     title: "Mark as In-Active",
      //     action: "mark_as_inactive",
      //     icon: "mdi-check-all",
      //     disabled: false,
      //   },
      // ],
      tabs: [
        {
          title: "Details",
          icon: "mdi-phone",
          key: "equipment",
          permission: "equipment",
          disabled: false,
          conditional: false,
        },
        {
          title: "Extended Info",
          icon: "mdi-info",
          key: "extended-info",
          permission: "equipment",
          disabled: false,
          conditional: false,
        },
        {
          title: "Cross Rent",
          icon: "mdi-info",
          key: "cross-rent-info",
          permission: "equipment",
          disabled: false,
          conditional: true,
        },
        {
          title: "Sale",
          icon: "mdi-info",
          key: "sale-info",
          permission: "equipment",
          disabled: false,
          conditional: true,
        },
        {
          title: "Rental",
          icon: "mdi-info",
          key: "rental-info",
          permission: "equipment",
          disabled: false,
          conditional: true,
        },
        {
          title: "Sub Rents",
          icon: "mdi-info",
          key: "sub-rent-info",
          permission: "equipment",
          disabled: false,
          conditional: true,
        },
        {
          title: "Attachment",
          icon: "mdi-phone",
          key: "attachment",
          permission: "attachment",
          disabled: false,
          conditional: false,
        },
        /*
        {
          title: "Attachments",
          icon: "mdi-phone",
          key: "image",
          permission: "equipment",
          disabled: false,
        },
        {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          permission: "warranty",
          disabled: false,
        },
        {
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },*/
        {
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        },
      ],
      formValid: true,
      formLoading: false,
      pageLoading: true,
      //equipmentTab: null,
      equipment: 0,
      equipmentMoreAction: [],
      faultyDialog: false,
      retireDialog: false,
      statusValue: null,
      statusVal: 0,
    };
  },
  components: {
    SubRentalItemsEquipment,
    DetailTemplate,
    Overview,
    ExtendedInformation,
    CrossRentInformation,
    SaleInformation,
    RentalItemsEquipment,
    Warranty,
    Tickets,
    AdditionalDocuments,
    Images,
    Quotations,
    Invoices,
    InternalHistoryDetail,
    statusConfirmation,
    retiredConfirmation,
  },
  methods: {
    getStatusColor(status = "") {
      let color = "cyan";
      switch (status) {
        case "Active":
          color = "green";
          break;
        case "In-Active":
          color = "yellow";
          break;
        case "Faulty":
          color = "orange";
          break;
        case "Retired":
          color = "red";
          break;
      }
      return color;
    },
    patchEquipmentParams(status, action = "") {
      const _this = this;
      _this.statusVal = status;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "equipments/status",
          data: {
            status,
            equipment: [_this.equipment],
            action,
            remark: null,
          },
        })
        .then(() => {
          _this.getEquipmentDetail();
        })
        .catch((error) => {
          _this.pageLoading = false;
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      this.statusValue = action;
      switch (action) {
        // case "duplicate":
        //   _this.duplicateEquipment();
        //   break;
        case "mark_as_active":
          _this.patchEquipmentParams(1, action);
          break;
        case "mark_as_inactive":
          _this.patchEquipmentParams(0, action);
          break;
        case "mark_as_repaired":
          _this.patchEquipmentParams(1, action);
          break;
        case "mark_as_not_retired":
          _this.patchEquipmentParams(1, action);
          break;
        case "mark_as_faulty":
          this.faultyDialog = true;
          _this.statusVal = 2;
          break;
        case "mark_as_retired":
          this.retireDialog = true;
          _this.statusVal = 3;
          break;
      }
    },
    duplicateEquipment() {
      this.$router.push(
        this.getDefaultRoute("equipment.create", {
          query: {
            duplicate: this.equipment,
          },
        })
      );
    },
    getEquipmentDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "equipment/" + _this.equipment,
        })
        .then(({ data }) => {
          _this.detail = data;

          if (data.more_actions) {
            this.equipmentMoreAction = data.more_actions;
            this.equipmentMoreAction = this.equipmentMoreAction.map((row) => {
              let newRow = row;
              newRow.hide = false;
              switch (newRow.action) {
                case "mark_as_faulty":
                  if (_this.detail.faulty || _this.detail.faulty == "1") {
                    //newRow.hide=true;
                    (newRow.action = "mark_as_repaired"),
                      (newRow.title = "Mark as Repaired"),
                      (newRow.disabled = false),
                      (newRow.icon = "mdi-check-all");
                  }
                  break;
                case "mark_as_retired":
                  if (_this.detail.retired || _this.detail.retired == "1") {
                    //newRow.hide=true;
                    (newRow.action = "mark_as_not_retired"),
                      (newRow.title = "Mark as Not Retired"),
                      (newRow.disabled = false),
                      (newRow.icon = "mdi-check-all");
                  }
                  break;
                case "mark_as_inactive":
                  if (
                    _this.detail.activated == 0 ||
                    _this.detail.activated == "0"
                  ) {
                    newRow.hide = true;
                  }
                  break;
                case "mark_as_active":
                  if (
                    _this.detail.activated == 1 ||
                    _this.detail.activated == "1"
                  ) {
                    newRow.hide = true;
                  }
                  break;
              }
              return { ...newRow };
            });
          }

          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Equipment", route: "equipment" },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getUserDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "current-user",
        })
        .then(({ data }) => {
          _this.userInfo = data;
          
          _this.allowPurchaseinfo = _this.userInfo?.is_manager;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getEquipmentDetail();
    _this.getUserDetail();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Equipment", route: "equipment" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.equipment = _this.$route.params.id;
    if (!_this.equipment || _this.equipment <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.equipmentTab = _this.$route.query.tab;
    }
  },
  computed: {
    // equipmentTab: {
    //   set(val) {
    //     let query = { ...this.$route.query };
    //     query.tab = val;
    //     if (this.equipmentTab != val) {
    //       this.$router.push({ query });
    //     }
    //   },
    //   get() {
    //     return this.$route.query.tab || "equipment";
    //   },
    // },
  },
};
</script>
